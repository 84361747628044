import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import PrivateRoute from '../../components/Security/privateRoute';

const Dashboard = () => {
  useEffect(() => {
    navigate('/dashboard/my-properties');
  }, []);

  return (
    <PrivateRoute>
      <DashboardLayout title="Контролно табло"></DashboardLayout>
    </PrivateRoute>
  );
};

export default Dashboard;
